<template>
<div>
    <v-col cols="12">
        <v-row>
            <v-col cols="12" lg="12" md="12">
                <v-card outlined>
                    <v-card-text>
                        Resumen General
                        <v-divider></v-divider>
                    </v-card-text>
                    <v-data-table class="elevation-0 scroll table table-hover table-condensed" group-by="Tipo" dense :headers="headerType" :items="itemsType" hide-default-footer :items-per-page="-1"></v-data-table>
                    <v-divider class="mt-2"></v-divider>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6" md="6">

                <v-card outlined>
                    <v-card-text>
                        Por Estudio
                        <v-divider></v-divider>
                    </v-card-text>
                    <v-data-table class="elevation-0 scroll table table-hover table-condensed" group-by="Estudio a Cargo" dense :headers="headerStudy" :items="itemsStudy" hide-default-footer :items-per-page="-1"></v-data-table>
                    <v-divider class="mt-2"></v-divider>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6" md="6">

                <v-row>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-text>
                                H. Legales - Gastos - Pretenciones
                                <v-divider></v-divider>
                            </v-card-text>
                            <v-data-table class="elevation-0 scroll table table-hover table-condensed" group-by="Tipo" dense :headers="headerMoney" :items="itemsMoney" hide-default-footer :items-per-page="-1">
                                <template v-slot:item.Total="{ item }">
                                    {{

                             item.Total >= 1000
                                ? item.Total.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : item.Total.toFixed(2)

                        }}
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>

                </v-row>

            </v-col>

           <!--  <v-col cols="12" lg="12" md="12">

                <v-card outlined>
                    <v-card-text>
                        Por Estado
                        <v-divider></v-divider>
                    </v-card-text>
                    <v-data-table class="elevation-0 scroll table table-hover table-condensed" dense :headers="headerStatus" :items="items" hide-default-footer :items-per-page="-1"></v-data-table>
                </v-card>

            </v-col> -->

        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-text>Reportes Graficos</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6" md="6">
                <v-card outlined>
                    <v-card-text>
                        Por Estado
                        <v-divider></v-divider>
                    </v-card-text>
                    <high-charts-pie :id="1" :items="itemsStatus" :visibleSubTitle="true" :subtitle="''" />
                </v-card>
            </v-col>
            <v-col cols="12" lg="6" md="6">
                <v-card outlined>
                    <v-card-text>
                        Casos Por Año
                        <v-divider></v-divider>
                    </v-card-text>
                    <high-charts-drill-bar :id="2" :items="dataAnio" :visibleSubTitle="true" :subtitle="''" />
                </v-card>
            </v-col>
        </v-row>
    </v-col>
</div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";

import HighChartsPie from '@/components/Utils/HighCharts/HighChartsPie.vue';
import HighChartsDrillBar from '@/components/Utils/HighCharts/HighChartsDrillBar.vue';
export default {
    name: "",
    components: {
        HighChartsPie,
        HighChartsDrillBar
    },
    props: {},
    data() {
        return {
            itemsStatus: [],
            items: [],
            headerStatus: [],

            itemsAnio: [],
            dataAnio: [],
            headerAnio: [],

            itemsType: [],
            headerType: [],
            itemsStudy: [],
            headerStudy: [],
            itemsMoney: [],
            headerMoney: [],
        }
    },
    created() {
        this.initialize()
    },

    methods: {

        initialize() {
            this.initializeStatus()
            this.initializeAnio()
            this.initializeByType()
            this.initializeByStudyInCharge()
            this.initializeMoney()
        },

        initializeStatus() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "BeginDate, EndDate"
            report.QryParamsArrayValue = "" + ', ' + "";
            report.QryNameProcedure = "LglComplaintsReport_Status";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.items = resp.data;

                            for (
                                var i = 0; i < Object.keys(resp.data[0]).length; i++
                            ) {
                                this.headerStatus.splice(i, i, {
                                    text: Object.keys(resp.data[0])[i],
                                    value: Object.keys(resp.data[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                            console.log(this.headerStatus)

                            //*********************** DATA PARA DASHBOARD ********************/
                            var objTmp = {}
                            this.itemsStatus = []

                            resp.data.forEach(element => {
                                //GRAFICO PIE
                                objTmp = {};
                                objTmp.name = element.Estado;
                                objTmp.y = element.Cantidad;

                                this.itemsStatus.push(objTmp);

                            });
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        initializeAnio() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "BeginDate, EndDate"
            report.QryParamsArrayValue = "" + ', ' + "";
            report.QryNameProcedure = "LglComplaintsReport_Anio";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsAnio = resp.data;

                            for (
                                var i = 0; i < Object.keys(resp.data[0]).length; i++
                            ) {
                                this.headerAnio.splice(i, i, {
                                    text: Object.keys(resp.data[0])[i],
                                    value: Object.keys(resp.data[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                            //*********************** DATA PARA DASHBOARD ********************/
                            var objTmp = {}
                            this.dataAnio = []

                            resp.data.forEach(element => {
                                //GRAFICO PIE
                                objTmp = {};
                                objTmp.name = element.Año;
                                objTmp.y = element.Cantidad;

                                this.dataAnio.push(objTmp);

                            });
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        initializeByType() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "BeginDate, EndDate"
            report.QryParamsArrayValue = "" + ', ' + "";
            report.QryNameProcedure = "LglComplaintsReport_byType";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsType = resp.data;

                            for (
                                var i = 0; i < Object.keys(resp.data[0]).length; i++
                            ) {
                                this.headerType.splice(i, i, {
                                    text: Object.keys(resp.data[0])[i],
                                    value: Object.keys(resp.data[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        initializeByStudyInCharge() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "BeginDate, EndDate"
            report.QryParamsArrayValue = "" + ', ' + "";
            report.QryNameProcedure = "LglComplaintsReport_byStudyInCharge";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsStudy = resp.data;

                            for (
                                var i = 0; i < Object.keys(resp.data[0]).length; i++
                            ) {
                                this.headerStudy.splice(i, i, {
                                    text: Object.keys(resp.data[0])[i],
                                    value: Object.keys(resp.data[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        initializeMoney() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "BeginDate, EndDate"
            report.QryParamsArrayValue = "" + ', ' + "";
            report.QryNameProcedure = "LglComplaintsReport_Money";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsMoney = resp.data;
                            for (
                                var i = 0; i < Object.keys(resp.data[0]).length; i++
                            ) {
                                this.headerMoney.splice(i, i, {
                                    text: Object.keys(resp.data[0])[i],
                                    value: Object.keys(resp.data[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
    },

};
</script>
